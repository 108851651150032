// imports
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { surveyLayer } from "@partials";
import { StoreApp, setSurveyDate } from "@store-common";

export const SurveyDate = ({ map }) => {
  const { surveyDate } = useSnapshot(StoreApp);
  const getSurveyFeature = (pixel) =>
    map.forEachFeatureAtPixel(pixel, (feature) => feature, {
      layerFilter: (layer) => layer === surveyLayer,
    });

  // Adds and removes survey layer from the map
  useEffect(() => {
    if (!map) return;
    map.addLayer(surveyLayer);
    return () => {
      map.removeLayer(surveyLayer);
    };
  }, [map]);

  // Updates survey date based on map position
  useEffect(() => {
    if (!map) return;
    const updateSurveyDate = () => {
      if (map.getView().getZoom() < surveyLayer.getMinZoom()) {
        setSurveyDate(null);
      } else {
        const mapCenter = map.getPixelFromCoordinate(map.getView().getCenter());
        const surveyFeature = getSurveyFeature(mapCenter);
        const surveyDate = surveyFeature?.properties_?.Rep_Ido ?? null;
        setSurveyDate(surveyDate);
      }
    };

    map.on("moveend", updateSurveyDate);
    return () => {
      map.un("moveend", updateSurveyDate);
    };
  }, [map, surveyLayer]);

  if (!surveyDate) return;
  return (
    <div className="footer__surveydate">
      <span className="span--bold">Felmérés: {surveyDate}</span>
    </div>
  );
};
