// Imports
import { setNewRequestPinAddress } from "@store-roof";
import { CheckError } from "utils/common";

const googleMapsApiKey = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_GOOGLE_PLACES_API_KEY_PROD 
  : process.env.REACT_APP_GOOGLE_PLACES_API_KEY_DEV;

// getProfile
export const getGeocode = async (lat, lng) => {
  await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=hu&key=${googleMapsApiKey}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    }
  )
    .then((response) => CheckError(response))
    .then((actualData) => {

      const county = actualData.results.find(addr_comp =>
        addr_comp.types[0] === "administrative_area_level_1"
      ).address_components[0].long_name;
      const postal_code = actualData.results[0].address_components.find(comp =>
        comp.types[0] === "postal_code"
      )?.long_name || null;
      const locality = actualData.results[0].address_components.find(comp =>
        comp.types[0] === "locality"
      )?.long_name || null;
      const political = actualData.results[0].address_components.find(comp =>
        comp.types[0] === "political"
      )?.short_name || null;
      const route = actualData.results[0].address_components.find(comp =>
        comp.types[0] === "route"
      )?.short_name || null;
      const street_number = actualData.results[0].address_components.find(comp =>
        comp.types[0] === "street_number"
      )?.long_name || null;
      const code = actualData.plus_code.global_code || null;

      const formattedAddress = (route && street_number)
        ? `${postal_code || "XXXX"} ${locality}${(political && political !== locality) ? " (" + political + ")": ""}, ${route} ${street_number}.`
        : `${postal_code || "XXXX"} ${locality}, ${code}`;

      const address = {
        formatted: formattedAddress,
        county,
        postal_code,
        locality,
        political,
        route,
        street_number,
        code
      };

      setNewRequestPinAddress(address);
    })
    .catch((error) => console.log(error));
};