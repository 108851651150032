export const getGeocodeData = async (lat, lng) => {
  const apiKey = process.env.NODE_ENV === 'production' 
    ? process.env.REACT_APP_GOOGLE_PLACES_API_KEY_PROD 
    : process.env.REACT_APP_GOOGLE_PLACES_API_KEY_DEV;
  
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    const county = data.results.find(
      (addr_comp) => addr_comp.types[0] === "administrative_area_level_1"
    ).address_components[0].long_name;
    const postal_code =
      data.results[0].address_components.find(
        (comp) => comp.types[0] === "postal_code"
      )?.long_name || null;
    const locality =
      data.results[0].address_components.find(
        (comp) => comp.types[0] === "locality"
      )?.long_name || null;
    const political =
      data.results[0].address_components.find(
        (comp) => comp.types[0] === "political"
      )?.short_name || null;
    const route =
      data.results[0].address_components.find(
        (comp) => comp.types[0] === "route"
      )?.short_name || null;
    const street_number =
      data.results[0].address_components.find(
        (comp) => comp.types[0] === "street_number"
      )?.long_name || null;
    const code = data.plus_code.global_code || null;

/*     const formattedAddress = (route && street_number)
    ? `${postal_code || "XXXX"} ${locality}${(political && political !== locality) ? " (" + political + ")": ""}, ${route} ${street_number}.`
    : `${postal_code || "XXXX"} ${locality}, ${code}`; */

    const formattedAddress = {
      formatted: (route && street_number)
      ? `${postal_code || "XXXX"} ${locality}${(political && political !== locality) ? " (" + political + ")": ""}, ${route} ${street_number}.`
      : `${postal_code || "XXXX"} ${locality}, ${code}`,
      county: county,
      postal_code: postal_code,
      locality: locality,
      political: political,
      route: route,
      street_number: street_number,
      code: code
    };

    return formattedAddress;
  } catch (error) {
    console.error(error);
    return null;
  }
};
